.root {
  height: 350px;
  overflow: hidden;
  position: relative;
}


.root .leaflet-marker-icon {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}
.root .leaflet-marker-icon {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.root .map {
  height: 350px;
  transform: scale(2.30);
}

.root :global(.leaflet-right) {
  transform: translate(-560px, 120px) scale(0.5);
}

.root :global(.leaflet-editing-icon) {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.root :global(.dg-control-round) {
  display: none;
}

.ctrls {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.ctrl {
  background-color: #f4f4f4;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 3px;
  cursor: pointer;
}

.ctrl.selected {
  background-color: #dfdfdf;
}
