.root {
  height: 350px;
}

.root .leaflet-marker-icon {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}
.root .leaflet-marker-icon {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.root .map {
  height: 350px;
}
