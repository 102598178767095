.wrapper {
  position: fixed;
  z-index: 6000;
  top:0;
  bottom:0;
  right:0;
  left:0;

  display: flex;
  justify-content: center;
  align-items: center;
}
