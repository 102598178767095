.wrapper {
  position: relative;
}

.toggleContainer {
  position: absolute;
  top: 30px;
  right: 30px;
}

.toggle {
  font-size: 1.5em;
  color: #3f51b5;
  cursor: pointer;
  margin-top: 20px;
  display: block;
}
