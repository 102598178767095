.Preloader_wrapper__3R2A2 {
  position: fixed;
  z-index: 6000;
  top:0;
  bottom:0;
  right:0;
  left:0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.CImageMapper_root__3bAsS {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CImageMapper_wrapper__2GQ0s {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: -webkit-linear-gradient(135deg, lightgray 25%, transparent 25%, transparent 50%, lightgray 50%, lightgray 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, lightgray 25%, transparent 25%, transparent 50%, lightgray 50%, lightgray 75%, transparent 75%, transparent);
  background-size: 7px 7px;
  width: 100%;
}

.CImageMapper_wrapper__2GQ0s.CImageMapper_editing__i2gHy {
  cursor: crosshair;
}

.CImageMapper_control__VqICm {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  width: 26px;
  left: 50px;
  overflow: hidden;
}

.CImageMapper_controlItem__2fJcR {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4e545e;
}

.CImageMapper_controlItem__2fJcR:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.CImageMapper_controlItem__2fJcR.CImageMapper_selected__3_7l9 {
  background-color: #dfdfdf;
}

.CImageMapper_attrsWrapper__1Nc8X {
  padding: 20px;
}

.CImageMapper_imagesSwitch__1Zh0M {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
}

.CImageMapper_imagesSwitchBall__3hzyr {
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: #838383;
  cursor: pointer;
}

.CImageMapper_imagesSwitchBall__3hzyr.CImageMapper_selected__3_7l9 {
  background-color: #df3c46;
}

.GMapEditor_root__2IaqF {
  height: 350px;
  overflow: hidden;
  position: relative;
}


.GMapEditor_root__2IaqF .GMapEditor_leaflet-marker-icon__37zop {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}
.GMapEditor_root__2IaqF .GMapEditor_leaflet-marker-icon__37zop {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.GMapEditor_root__2IaqF .GMapEditor_map__3YwYh {
  height: 350px;
  -webkit-transform: scale(2.30);
          transform: scale(2.30);
}

.GMapEditor_root__2IaqF .leaflet-right {
  -webkit-transform: translate(-560px, 120px) scale(0.5);
          transform: translate(-560px, 120px) scale(0.5);
}

.GMapEditor_root__2IaqF .leaflet-editing-icon {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.GMapEditor_root__2IaqF .dg-control-round {
  display: none;
}

.GMapEditor_ctrls__1d99R {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.GMapEditor_ctrl__2-8oc {
  background-color: #f4f4f4;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 3px;
  cursor: pointer;
}

.GMapEditor_ctrl__2-8oc.GMapEditor_selected__3oUzo {
  background-color: #dfdfdf;
}

.YMapEditor_root__1CRHg {
  height: 350px;
  overflow: hidden;
  position: relative;
}


.YMapEditor_root__1CRHg .YMapEditor_leaflet-marker-icon___6jzW {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}
.YMapEditor_root__1CRHg .YMapEditor_leaflet-marker-icon___6jzW {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.YMapEditor_root__1CRHg .YMapEditor_map__w9Iys {
  height: 350px;
  /*transform: scale(2.30);*/
}

.YMapEditor_root__1CRHg .leaflet-right {
  //transform: translate(-560px, 120px) scale(0.5);
}

.YMapEditor_root__1CRHg .leaflet-editing-icon {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.YMapEditor_root__1CRHg .dg-control-round {
  display: none;
}

.YMapEditor_ctrls__3eLOq {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.YMapEditor_ctrl__2194Z {
  background-color: #f4f4f4;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 3px;
  cursor: pointer;
}

.YMapEditor_ctrl__2194Z.YMapEditor_selected__3rSpL {
  background-color: #dfdfdf;
}

.CamerasMap_root__2VfjZ {
  height: 350px;
}

.CamerasMap_root__2VfjZ .CamerasMap_leaflet-marker-icon__OaGDj {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}
.CamerasMap_root__2VfjZ .CamerasMap_leaflet-marker-icon__OaGDj {
  max-width: 2px;
  max-height: 2px;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.CamerasMap_root__2VfjZ .CamerasMap_map__3R2Cc {
  height: 350px;
}

.ParkingLotsEditorView_root__qOL3g {

}

.ParkingLotsEditorView_maps__3uXOJ {
  display: flex;
}

.ParkingLotsEditorView_maps__3uXOJ > div {
  width: 50%;
  margin: 5px;
}

.ParkingLotsEditorView_actions__1J0Pd {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
}

.ParkingLotsEditorView_actions__1J0Pd > button {
  margin-bottom: 10px;
}

.DebugView_root__h9b28 {
}

.DebugView_camerasWrapper__3rC0E {
}

.DebugView_camerasWrapper__3rC0E > div {
  width: 100%;
}

.DebugView_actions__3Lt1D {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 1000;
}

.DebugView_cameraPlacesStatus__2Q-zQ {
  background: white;
  border: 1px solid gray;
  text-align: left;
  padding: 20px;
  position: fixed;
  left: 10px;
  bottom: 10px;
}

.DebugView_cameraPlacesStatus__2Q-zQ > div {
  font-size: 1.2rem;
  font-weight: 500;
}

.DebugView_cameraPlacesStatus__2Q-zQ > div:nth-child(2) > span {
  color: green;
  font-weight: bold;
}

.DebugView_cameraPlacesStatus__2Q-zQ > div:nth-child(3) > span {
  color: darkred;
  font-weight: bold;
}

.DebugView_cameraPlacesStatus__2Q-zQ > div:nth-child(4) > span {
  color: gray;
  font-weight: bold;
}

.DebugView_actions__3Lt1D > button {
  margin-bottom: 10px;
}

.CameraImageLots_wrapper__3dpHi {
  position: relative;
}

.CameraImageLots_toggleContainer__2xEZL {
  position: absolute;
  top: 30px;
  right: 30px;
}

.CameraImageLots_toggle__1ck80 {
  font-size: 1.5em;
  color: #3f51b5;
  cursor: pointer;
  margin-top: 20px;
  display: block;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

canvas[resize] {
  width: 100%;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.LoginView_main__b6n26 {
    height: calc(100vh - 64px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.LoginView_box__3LuKJ {
    width: 400px;
    height: 300px;
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

canvas[resize] {
  width: 100%;
  height: 100%;
}

