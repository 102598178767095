.root {
}

.camerasWrapper {
}

.camerasWrapper > div {
  width: 100%;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 1000;
}

.cameraPlacesStatus {
  background: white;
  border: 1px solid gray;
  text-align: left;
  padding: 20px;
  position: fixed;
  left: 10px;
  bottom: 10px;
}

.cameraPlacesStatus > div {
  font-size: 1.2rem;
  font-weight: 500;
}

.cameraPlacesStatus > div:nth-child(2) > span {
  color: green;
  font-weight: bold;
}

.cameraPlacesStatus > div:nth-child(3) > span {
  color: darkred;
  font-weight: bold;
}

.cameraPlacesStatus > div:nth-child(4) > span {
  color: gray;
  font-weight: bold;
}

.actions > button {
  margin-bottom: 10px;
}
