.main {
    height: calc(100vh - 64px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.box {
    width: 400px;
    height: 300px;
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}