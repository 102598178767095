.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: linear-gradient(-45deg, lightgray 25%, transparent 25%, transparent 50%, lightgray 50%, lightgray 75%, transparent 75%, transparent);
  background-size: 7px 7px;
  width: 100%;
}

.wrapper.editing {
  cursor: crosshair;
}

.control {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  width: 26px;
  left: 50px;
  overflow: hidden;
}

.controlItem {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4e545e;
}

.controlItem:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.controlItem.selected {
  background-color: #dfdfdf;
}

.attrsWrapper {
  padding: 20px;
}

.imagesSwitch {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
}

.imagesSwitchBall {
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: #838383;
  cursor: pointer;
}

.imagesSwitchBall.selected {
  background-color: #df3c46;
}
