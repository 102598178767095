.root {

}

.maps {
  display: flex;
}

.maps > div {
  width: 50%;
  margin: 5px;
}

.actions {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
}

.actions > button {
  margin-bottom: 10px;
}
